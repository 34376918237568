import { VerifyOtpParams } from '@supabase/supabase-js'

export async function supabaseVerifyAuthCode(params: VerifyOtpParams) {
    const supabaseClient = useSupabaseClient()

    const response = await supabaseClient.auth.verifyOtp(params)
    const nowUtcTimestamp = new Date().getTime()
    const { $trackEvent } = useNuxtApp()

    if (response.error) {
        throw new Error(
            SupabaseErrorTranslations[response.error.message] ||
                response.error.message
        )
    }

    const createdAtUtc = new Date(response.data.user!.created_at).getTime()

    // check user was created within the last 5 minutes
    if (nowUtcTimestamp - createdAtUtc < 300000) {
        $trackEvent({
            eventName: AnalyticsEvent.NewUser,
            eventProperties: {
                userId: response.data.user!.id,
            },
        })
    }

    return response
}
