<template>
    <div class="w-full">
        <AuthenticationInformation
            v-if="step === AuthenticationStep.Information"
        />
        <AuthenticationVerification v-else />
    </div>
</template>

<script setup lang="ts">
import { injectLocal } from '@vueuse/core'

import {
    authenticationInjectionKey,
    AuthenticationStep,
    AuthenticationUIContext,
    setupAuthenticationProvider,
} from '~/components/Authentication/authenticationProvider'

const props = defineProps<{
    uiContext: AuthenticationUIContext
}>()

setupAuthenticationProvider(props.uiContext)
const { $trackEvent } = useNuxtApp()

onMounted(() => {
    $trackEvent({
        eventName: AnalyticsEvent.ViewSingInPrompt,
    })
})

const { step } = injectLocal(authenticationInjectionKey)!
</script>
