export async function supabaseResendAuthCode({
    phone,
    email,
}: {
    phone?: string
    email?: string
}) {
    const supabaseClient = useSupabaseClient()

    let response
    if (phone) {
        response = await supabaseClient.auth.resend({ type: 'sms', phone })
    } else {
        response = await supabaseClient.auth.signInWithOtp({ email: email! })
    }

    if (response.error) {
        throw new Error(
            SupabaseErrorTranslations[response.error.message] ||
                response.error.message
        )
    }
    return response
}
