<template>
    <form class="flex flex-col" @submit.prevent="handleFormSubmit">
        <AInputLabel class="mb-2">{{ title }}</AInputLabel>
        <SegmentedControl
            v-model="selectedTab"
            :toggle-items="[
                { name: 'SMS', slotName: 'sms' },
                { name: 'Email', slotName: 'email' },
            ]"
            :wrapper-class="[`w-full mb-6`, !isExpanded ? `sm:w-fit` : ``]"
            button-class="flex-1 sm:flex-auto"
        >
            <template #sms>
                <div class="flex flex-col gap-4">
                    <AInput
                        id="phone-input"
                        ref="phoneInput"
                        v-model="phoneNumberWithoutCountryCode"
                        label="Phone Number"
                        :left-component="AuthenticationCountryCodeDropdown"
                        :left-component-props="{
                            'model-value': countryCode,
                            'onUpdate:model-value': (val: any) => {
                                countryCode = val
                            },
                        }"
                        autofocus
                        :error-message="errorMessage"
                        placeholder="Enter Phone Number"
                        class="border-none bg-transparent pl-0"
                    />
                </div>
            </template>
            <template #email>
                <AInput
                    id="email-input"
                    ref="emailInput"
                    v-model="email"
                    :error-message="errorMessage"
                    autofocus
                    placeholder="Enter Email Address"
                    label="Email"
                />
            </template>
        </SegmentedControl>
        <AButton
            :class="[` w-full sm:w-fit`, isExpanded ? `mt-20` : `mt-10`]"
            :loading="isPending"
            >Send Code</AButton
        >
    </form>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { injectLocal } from '@vueuse/core'
import { toast } from 'vue-sonner'

import AuthenticationCountryCodeDropdown from '~/components/Authentication/AuthenticationCountryCodeDropdown.vue'
import {
    authenticationInjectionKey,
    AuthenticationStep,
    formatPhoneNumber,
} from '~/components/Authentication/authenticationProvider'
import { supabaseGenerateAuthCode } from '~/lib/api/mutations/supabaseGenerateAuthCode'

const {
    method,
    email,
    uiContext,
    phoneNumberWithoutCountryCode,
    countryCode,
    step,
} = injectLocal(authenticationInjectionKey)!

const { $trackEvent } = useNuxtApp()

const selectedTab = ref(method.value === 'phone' ? 0 : 1)
const errorMessage = ref<string | undefined>(undefined)
const phoneInput = ref<any>()
const emailInput = ref<any>()
const { isPending, mutate: mutateGenerateAuthCode } = useMutation({
    mutationFn: supabaseGenerateAuthCode,
    onSuccess: () => {
        step.value = AuthenticationStep.Verification
    },
    onError: (error) => {
        toast.error(error.message)
    },
})

onMounted(() => {
    if (method.value === 'phone') {
        setTimeout(() => {
            phoneInput.value.$el!.focus()
        }, 50)
    } else {
        setTimeout(() => {
            emailInput.value.$el!.focus()
        }, 50)
    }
})

watch(countryCode, (val) => {
    setTimeout(() => {
        phoneInput.value.$el!.focus()
    }, 50)
})

watch(selectedTab, (val) => {
    errorMessage.value = undefined
    if (val === 0) {
        method.value = 'phone'
        $trackEvent({
            eventName: AnalyticsEvent.ToggleToSMSSingIn,
        })
        setTimeout(() => {
            phoneInput.value.$el!.focus()
        }, 50)
    } else {
        method.value = 'email'
    }
})

async function handleFormSubmit() {
    if (method.value === 'phone' && !phoneNumberWithoutCountryCode.value) {
        errorMessage.value = 'Phone number is required'
        return
    }

    if (method.value === 'email' && !email.value) {
        errorMessage.value = 'Email is required'
        return
    }

    errorMessage.value = undefined

    mutateGenerateAuthCode({
        ...(method.value === 'phone'
            ? {
                  phone: formatPhoneNumber(
                      phoneNumberWithoutCountryCode.value,
                      countryCode.value
                  ),
              }
            : { email: email.value }),
    })
}

const title = computed(() => {
    if (uiContext === 'trip_creation_flow') {
        return 'Verify Via'
    } else {
        return 'Access your account via'
    }
})

const isExpanded = computed(() => uiContext !== 'trip_creation_flow')
</script>
