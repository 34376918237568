import { SignInWithPasswordlessCredentials } from '@supabase/supabase-js'

export async function supabaseGenerateAuthCode(
    creds: SignInWithPasswordlessCredentials
) {
    const supabaseClient = useSupabaseClient()

    const response = await supabaseClient.auth.signInWithOtp(creds)

    if (response.error) {
        throw new Error(
            SupabaseErrorTranslations[response.error.message] ||
                response.error.message
        )
    }

    return response
}
