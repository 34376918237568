<template>
    <div class="flex flex-col gap-6">
        <div class="">{{ headerText }}</div>
        <div class="flex flex-row gap-3 text-base">
            <input
                v-for="i in codeLength"
                :key="i - 1"
                :ref="
                    (e) => {
                        inputRefs[i - 1] = e as HTMLInputElement
                    }
                "
                type="tel"
                :maxlength="1"
                class="flex aspect-[40/48] w-2 min-w-0 flex-1 rounded-lg border border-neutral-200 p-2 text-center"
                v-bind="{
                    ...(i === 1 && { autofocus: true }),
                }"
                @input="(e) => handleInputChange(e, i - 1)"
                @focus="handleFocus"
                @keydown="(e) => handleKeyDown(e, i - 1)"
                @paste="handlePaste"
            />
        </div>
        <transition name="fade" mode="out-in">
            <div
                v-if="!isVerifying"
                key="1"
                class="flex flex-row justify-between"
            >
                <component
                    :is="resendInSeconds <= 0 ? `button` : `div`"
                    class="text-neutral-500"
                    v-bind="{
                        ...(resendInSeconds <= 0 && {
                            onClick: resendCode,
                        }),
                    }"
                >
                    {{ resendText }}
                </component>
                <button class="" @click="step = AuthenticationStep.Information">
                    Try another {{ method === 'phone' ? `phone` : `email` }}
                </button>
            </div>
            <div v-else key="2" class="text-neutral-500">Authenticating...</div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import { injectLocal } from '@vueuse/core'
import { toast } from 'vue-sonner'

import {
    authenticationInjectionKey,
    AuthenticationStep,
    formatPhoneNumber,
} from '~/components/Authentication/authenticationProvider'
import { supabaseResendAuthCode } from '~/lib/api/mutations/supabaseResendAuthCode'
import { supabaseVerifyAuthCode } from '~/lib/api/mutations/supabaseVerifyAuthCode'

const { email, phoneNumberWithoutCountryCode, countryCode, method, step } =
    injectLocal(authenticationInjectionKey)!
const { $trackEvent } = useNuxtApp()

const inputRefs = ref<HTMLInputElement[]>([])
const code = ref('')
const codeLength = 6
const { mutate: mutateVerifyCode, isPending: isVerifying } = useMutation({
    mutationFn: supabaseVerifyAuthCode,
    onSuccess: () => {
        $trackEvent({
            eventName: AnalyticsEvent.SingInFromCTA,
        })
    },
    onError: (error) => {
        toast.error(error.message)
    },
})

const { mutate: mutateResendCode, isPending: isResending } = useMutation({
    mutationFn: supabaseResendAuthCode,
})

const resendPeriod = 30
const resendInSeconds = ref(resendPeriod)
let intervaleTimer: NodeJS.Timeout

onMounted(() => {
    inputRefs.value[0].focus()
    intervaleTimer = setInterval(() => {
        if (resendInSeconds.value <= 0) {
            clearInterval(intervaleTimer)
            return
        }
        resendInSeconds.value--
    }, 1000)
})

function resendCode() {
    mutateResendCode({
        ...(method.value === 'phone'
            ? {
                  phone: formatPhoneNumber(
                      phoneNumberWithoutCountryCode.value,
                      countryCode.value
                  ),
              }
            : {
                  email: email.value,
              }),
    })
    resendInSeconds.value = resendPeriod
    toast.info(
        `A new code has been sent to ${
            method.value === 'phone'
                ? formatPhoneNumber(
                      phoneNumberWithoutCountryCode.value,
                      countryCode.value
                  )
                : email.value
        }`
    )
}

const resendText = computed(() => {
    return resendInSeconds.value <= 0
        ? `Resend Code`
        : `Resend in ${resendInSeconds.value}s`
})

watch(code, (val) => {
    if (val.length === codeLength) {
        mutateVerifyCode({
            token: code.value,
            ...(method.value === 'phone'
                ? {
                      phone: formatPhoneNumber(
                          phoneNumberWithoutCountryCode.value,
                          countryCode.value
                      ),
                      type: 'sms',
                  }
                : {
                      email: email.value,
                      type: 'email',
                  }),
        })
    }
})

const headerText = computed(() => {
    const beginningText = `Enter the code sent to`
    if (method.value === 'phone') {
        return `${beginningText} ${formatPhoneNumber(
            phoneNumberWithoutCountryCode.value,
            countryCode.value
        )}`
    } else {
        return `${beginningText} ${email.value}`
    }
})

function handleInputChange(e: Event, index: number) {
    const input = e.target as HTMLInputElement

    const codeHasNonDigits = input.value.match(/\D/)
    if (codeHasNonDigits) {
        input.value = ''
        return
    }

    const nextInput = inputRefs.value[index + 1]

    // Update code state with single digit
    // const newCode = [...code.value]

    // // Convert lowercase letters to uppercase
    // if (/^[a-z]+$/.test(input.value)) {
    //     const uc = input.value.toUpperCase()
    //     newCode[index] = uc
    //     inputRefs.value[index].value = uc
    // } else {
    //     newCode[index] = input.value
    // }

    let newCode = ''

    inputRefs.value.forEach((inputRef) => {
        if (inputRef.value.length === 1) {
            newCode += inputRef.value
        }
    })

    code.value = newCode

    if (input.value === '') {
        // do nothing, handleKeyDown will handle focus
    } else if (nextInput) {
        // Select next input on entry, if exists
        nextInput.select()
    }
}

function handleFocus(e: FocusEvent) {
    ;(e.target as HTMLInputElement).select()
}

function handleKeyDown(e: KeyboardEvent, index: number) {
    const input = e.target as HTMLInputElement
    const previousInput = inputRefs.value[index - 1]

    if (e.key === 'Delete' || e.key === 'Backspace') {
        e.preventDefault()

        input.value = ''
        if (previousInput) {
            previousInput.focus()
        }
    }
}

const handlePaste = (e: ClipboardEvent) => {
    const pastedCode = e.clipboardData?.getData('text')
    const codeHasNonDigits = pastedCode?.match(/\D/)

    if (pastedCode?.length === 6 && !codeHasNonDigits) {
        code.value = pastedCode

        inputRefs.value.forEach((inputRef, index) => {
            inputRef.value = pastedCode.charAt(index)
        })

        e.preventDefault()
        e.stopPropagation()

        inputRefs.value[codeLength - 1].focus()
    }
}
</script>
