<template>
    <TabGroup
        :selected-index="selectedIndex"
        @change="
            (tabIndex: any) => {
                selectedIndex = tabIndex
            }
        "
    >
        <TabList
            :class="[
                `relative z-10 flex w-auto flex-row rounded-full bg-neutral-200 p-1`,
                wrapperClass,
            ]"
        >
            <Tab
                v-for="toggleItem in toggleItems"
                :id="toggleItem.slotName"
                :key="toggleItem.slotName"
                v-slot="{ selected }"
                ref="tabs"
                as="template"
            >
                <button
                    :class="[
                        'relative line-clamp-1 truncate rounded-full px-4 py-1 ring-blue-400 ring-offset-2 ring-offset-neutral-200 focus:outline-none focus:ring-2 ',
                        selected
                            ? 'bg-neutral-50 text-neutral-1000 '
                            : 'text-neutral-600',
                        buttonClass,
                    ]"
                >
                    {{ toggleItem.name }}
                </button>
            </Tab>
        </TabList>
        <TabPanels class="w-full">
            <TabPanel
                v-for="toggleItem in toggleItems"
                :id="toggleItem.slotName"
                :key="toggleItem.slotName"
            >
                <slot :name="toggleItem.slotName" />
            </TabPanel>
        </TabPanels>
    </TabGroup>
</template>

<script setup lang="ts">
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { ref } from 'vue'

const selectedIndex = defineModel<number>()
interface ToggleItem {
    name: string
    slotName: string
}
const { toggleItems } = defineProps<{
    toggleItems: ToggleItem[]
    wrapperClass: string | string[]
    buttonClass: string | string[]
}>()
defineOptions({ inheritAttrs: false })

const tabs = ref<ComponentPublicInstance[]>()
</script>
